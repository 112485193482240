/********************************   loading animation   ****************************************/
.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.box{
    width: 50px;
    height: 5px;
    background-color: #4169e1;
    border-radius: 10px;
    animation: anim 4s infinite;
    position: relative;
}
.box::before{
    content: "";
    position: absolute;
    left: 0;
    top: 50px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #4169e1;
    animation: anim2 2s infinite;
}
.box::after{
    content: "";
    position: absolute;
    right: 0;
    bottom: 50px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #4169e1;
    animation: anim3 2s infinite;
}
@keyframes anim {
    0% , 100% {transform: rotate(0deg);}
    50% {transform: rotate(360deg);}
}
@keyframes anim2 {
    0% , 100% {transform: translateY()}
    50% {
        transform: translateY(-45px) translateX(20px);
        width: 10px;
        height: 10px;
    }
}
@keyframes anim3 {
    0% , 100% {transform: translateY()}
    50% {
        transform: translateY(45px) translateX(-20px);
        width: 10px;
        height: 10px;
    }
}
/************************************************************************/

/**************************   loading spinner animation  ****************************************/
.loader,.loader:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
}
.loader {
    font-size: 16px;
    /* position: absolute;
    z-index: 3;
    top: 9;
    left: 50%; */
    text-indent: -9999em;
    border-top: 0.25em solid #DFF6FF;
    border-right: 0.25em solid #DFF6FF;
    border-bottom: 0.25em solid #DFF6FF;
    border-left: 0.25em solid #7878c7;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 0.8s infinite linear;
    animation: load8 0.8s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/************************************************************************************************/