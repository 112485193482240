@import 'variables';

.primary-btn {
    background-color: $first-color;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 9px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;

    &:hover {
        background-color: $second-color;
    }
}
.loading-btn {
    background-color: $third-color;
    color: $first-color;
    cursor: default;

    &:hover {
        background-color: $third-color;
    }
}

.secondary-btn {
    background-color: transparent;
    color: white;
    padding: 9px 18px;
    border: 1px solid rgba($color: $second-color, $alpha: 0.2);
    border-radius: 9px;
    font-size: 14px;
    font-weight: 600;
    color: $second-color;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;

    &:hover{
        background-color: $third-color;
    }
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    // margin-bottom: 15px;
    width: 100%;
}
.label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    margin-left: 14px;
}
.input {
    padding: 10px 16px;
    border-radius: 9px;
    font-size: 14px;
    border: 1px solid rgba($color: $second-color, $alpha: 0.2);
    background-color: transparent;
    width: calc(100% - 32px);

    &:focus {
        outline: none;
        border: 1px solid $second-color;
    }
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    width: 100%;

    @media (min-width: 1024px) { // lg: in Tailwind corresponds to a min-width media query at 1024px
      grid-template-columns: repeat(2, 1fr);
    }
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    width: 100%;

    @media (min-width: 1024px) { // lg: in Tailwind corresponds to a min-width media query at 1024px
        grid-template-columns: repeat(3, 1fr);
    }
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.row-between{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.card{
    background-color: #ffffff;
    border-radius: 12px;
    padding: 8px 12px;
    // height: calc(100% - 16px);

    .cardTitle{
        color: $second-color;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }
    .cardBtn{
        border: 1px solid rgba($color: $second-color, $alpha: 0.2);
        background-color: transparent;
        border-radius: 4px;
        color: rgba($color: $second-color, $alpha: 0.8);
        cursor: pointer;

        &:hover{
            background-color: $third-color;
        }
    }
}


.checkBox{
    border: 1px solid rgba($color: $second-color, $alpha: 0.2);
    height: 18px;
    width: 18px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background-color: $fourth-color;
    }
}

.checkedCheckBox{
    background-color: $third-color;
}

.progressBarWrapper{
    display: flex;
    align-items: center;
    gap: 12px;

    .progressBar {
        width: 100%;
        background-color: #e0e0e0;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        height: 10px;

        .progress {
            height: 10px;
            background-color: #4caf50;
            border-radius: 10px;
            transition: width 0.4s ease;
            position: relative;
            display: flex;
            align-items: center;
        }
    }
    .progressLabel {
        color: #888;
        font-size: 12px;
    }
}
