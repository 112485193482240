@import 'variables';

.mainContainer{
    padding: 12px;
}
.container{
    background-color: #fff;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    width: 100%;
    height: calc(100vh - 24px);
    border-radius: 16px;
    padding: 10px 16px;
}

.title{
    font-size: 16px;
    font-weight: 600;
    color: $first-color;
}

.mainBtn{
    height: max-content;
    border: 0;
    background-color: $fourth-color;
    color: #fff;
    padding: 10px 26px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;

    &:hover{
        background-color: $second-color;
    }
}

.projectTeam_usersWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child){
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.15);
        padding-bottom: 12px;
    }
}


.floatStatusMenu {
    position: absolute;
    left: 0;
    top: 0;
    // transform: translate(0, 0);
    z-index: 10;
    height: max-content;
    width: max-content;
    min-width: 100px;
    background-color: #fff;
    // padding: 8px 16px 8px 8px;
    border-radius: 8px;
    border: 1px solid $fourth-color;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        width: max-content;
        cursor: pointer;
        margin: 8px 16px 8px 8px;

        &:hover {
            background-color: $third-color;
            color: $second-color;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    .currStatus {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
        width: 100%;
        margin: 0;
        padding: 8px;
        font-size: 12px;
        font-weight: 600;
        background-color: rgba($color: #000000, $alpha: 0.07);
        border-radius: 8px 8px 0 0;
        cursor: default;
        color: #000;

        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.07);
            color: #000;
        }
    }

}

.status {
    background-color: rgba($color: #000000, $alpha: 0.1);
    padding: 4px 12px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    width: max-content;
}



.kanbanContainer {
    width: 100%;
    overflow-x: hidden;
    position: relative;
}

.kanbanBoard {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: auto;
}

.kanbanColumn {
    min-width: 260px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: red;
    padding: 8px 0;

    .header {
        padding: 0 10px;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        padding: 10px;
    }
}
