@import 'variables';

.container{
    // background-color: #fff;
    // background-color: #131133;
    height: calc(100vh);
    padding: 12px;
    display: flex;
    gap: 12px;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
}
.container-light{
    background-color: #fff;
}
.container-dark{
    background-color: #131133;
}

.no-select {
    user-select: none; /* Standard syntax */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.sideBarContainer{
    width: calc(var(--side-bar-width) - 24px);
}
.sideBarContainerOpen{
    min-width: 15%;    
}
.sideBar{
    // width: calc(var(--side-bar-width) - 24px);
    width: 100%;
    padding: 12px 6px;
    height: 100%;
    transition: all 0.3s;
    animation-fill-mode: forwards;
    color: $third-color;
    // background-color: red;
    background-color: $second-color;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: -8px -8px 24px 0px hsla(0, 0%, 100%, .5), 0px 0px 24px 0px rgba(29, 41, 57, .15);
}
.settingsSideBar{
    // width: calc(var(--side-bar-width) - 24px);
    width: 100%;
    padding: 12px 6px;
    height: 100%;
    transition: all 0.3s;
    animation-fill-mode: forwards;
    color: $second-color;
    // background-color: red;
    background-color: $third-color;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: -8px -8px 24px 0px hsla(0, 0%, 100%, .5), 0px 0px 24px 0px rgba(29, 41, 57, .15);
}
.sideBarOpen{
    animation: openSideTest 0.5s forwards;
}

.openSideBarIcon-btn{
    // background-color: $second-color;
    width: 22px;
    height: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}

.openSideBarIcon-V{
    transform: rotate(0deg);
}
.openSideBarIcon-H{
    transform: rotate(90deg);
}

.nav{
    list-style-type: none;
    padding: 0;
    // background-color: red;
    // width: 36px;

    li:not(:last-child) {
        margin-bottom: 12px;
    }
}
.nav-btn{
    padding: 8px 10px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $third-color;
    // color: red;

    // &:not(:last-child) {
    //     margin-bottom: 12px;
    // }
    span{
        font-size: 12px;
        font-weight: 600;
        margin-left: 8px;
    }
}
.nav-btn-light{
    color: $second-color;

    &:hover{
        background-color: $second-color;
        color: $third-color;
    }
}
.nav-btn-dark{
    color: $third-color;
    &:hover{
        background-color: $third-color;
        color: $second-color;
    }
}

.selected-nav-dark{
    background-color: $third-color;
    color: $second-color;
}
.selected-nav-light{
    background-color: $second-color;
    color: $third-color;
}
.selected-subNav{
    background-color: $first-color;
}

.navSubLine{
    height: 1px;
    width: 100%;
    background-color: $third-color;
    margin-bottom: 12px;
}

.subMenuIcon{
    height: 22px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}

.DashboardsNavWrapper{
    background-color: $second-color;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 12px;
    
    ul{
        list-style-type: none;
        padding: 0;
    }
}
.sideBarLinesMenuWrapper{
    position: absolute;
    height: max-content;
    width: max-content;
    // background-color: rgba($color: red, $alpha: 0.3);
    top: 0;
    right: -24px;
    transform: translate(100%, 0);

    background-color: $second-color;
    padding: 8px;
    border-radius: 8px;
    
    ul{
        list-style-type: none;
        padding: 0;
    }
}

.dashPoint{
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background-color: $third-color;
} 
.selectedDash{
    background-color: $fifth-color;
}
.DashboardsNavBtn{
    border: 1px solid $third-color;
    background-color: transparent;
    padding: 6px 12px;
    border-radius: 12px;
    color: $third-color;
    font-size: 12px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;

    &:hover{
        background-color: $third-color;
        color: $second-color;
    }
}

.content{
    width: calc(var(--content-width));
    height: calc(100%);
    background-color: #F8F9FB;
    // background-color: #fff;
    border-radius: 16px;
    padding: 16px;
    transition: all 0.3s;
    overflow-y: auto;
    // border: 1px solid rgba($color: #000000, $alpha: 0.1)
    // box-shadow: 2px 4px 15px 4px rgba(0,0,0,0.15);
}
.content-light{
    background-color: #fff;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
}
.content-dark{
    background-color: #F8F9FB;
    border: none;
}


// @keyframes openSideTest {
//     0% { width: calc(5% - 24px); }
//     60% { width: calc(21% - 24px); }
//     100% { width: calc(20% - 24px); }
// }
@keyframes openSideTest {
    0% { width: 10% }
    // 60% { width: 110% }
    100% { width: 100% }
}
@keyframes expand {
    from {
      font-size: 0;
      opacity: 0;
      font-size: 0;
    }
    to {
      font-size: inherit; 
      opacity: 1; 
    }
  }
  
.expandable {
    animation: expand 0.5s forwards;  
}




.globalHeader{
    display: flex;
    justify-content: space-between;

    p{
        margin: 0;
        font-size: 14px;
        font-weight: 600;
    }
    .iconWrapper{
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        border-radius: 100%;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin: 0;
        cursor: pointer;
    }
}

.floatSettingsMenu{
    position: absolute;
    right: 0;
    bottom: -6px;
    transform: translate(0, 100%);
    z-index: 10;
    height: max-content;
    width: max-content;
    min-width: 100px;
    background-color: #fff;
    padding: 8px 0;
    border-radius: 8px;
    border: 1px solid $fourth-color;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    li{
        color: rgba($color: #000, $alpha: 0.6);
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        // padding: 6px 12px;
        // border-radius: 6px;
        position: relative;
    
        &:hover{
            background-color: $third-color;
            color: $second-color;
        }
    
        &:not(:last-child){
            margin-bottom: 8px;
        }
    }
}