

.status{
  background-color: rgba($color: #000000, $alpha: 0.1);
  padding: 2.5px 12px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #000;
  width: max-content;
}
.progress{
  background-color: #D3E5EF;
}
.finish{
  background-color: #DBEDDB;
}

.priority{
  // margin-left: 12px;
  background-color: rgba($color: #000000, $alpha: 0.1);
  padding: 2.5px 12px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #000;
  width: max-content;
}
.medium{
  background-color: #FEEBC8;
}
.hight{
  color: #5D1715;
  background-color: #FFE2DD;
}

.bg-test{
  background-color: red;
}