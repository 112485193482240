

.modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 50;

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 10;
        padding: 12px;
        width: fit-content;
        min-width: 30%;
        max-width: 768px;
        max-height: calc(100% - 4rem);
        border-radius: 0.75rem;
        //overflow-y: auto;
        //overflow-x: hidden;
        flex-wrap: wrap;
    }

    .title{
        font-weight: bold;
        font-size: 1rem;
        color: #6b7280;
        padding: 0;
        margin: 0;
    }
    .closeBtn{
        width: 1.5rem;
        height: 1.5rem;
        color: #6b7280;
        cursor: pointer;
        padding: 0;
    }
    .bg{
        position: absolute;
        z-index: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100vh;
    }
}

.sideModal{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 50;

    .content {
        position: absolute;
        top: 0;
        right: 0;
        // transform: translate(-50%, -50%);
        background-color: white;
        z-index: 10;
        padding: 6px 12px;
        width: fit-content;
        min-width: 50%;
        max-width: 50%;
        height: 100%;
        border-radius: 12px 0 0 12px;
        overflow-y: auto;
        overflow-x: hidden;
        flex-wrap: wrap;
    }

    .title{
        font-weight: bold;
        font-size: 1rem;
        color: #6b7280;
        padding: 0;
        margin: 0;
    }
    .closeBtn{
        width: 1.5rem;
        height: 1.5rem;
        color: #6b7280;
        cursor: pointer;
        padding: 0;
    }
    .bg{
        position: absolute;
        z-index: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100vh;
    }
}
