.App {
  text-align: center;
}

.Link{
  text-decoration: none;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  // box-shadow: 0 0 5px rgba(120, 120, 199, 0.8);
  box-shadow: none;
}

.customShadow{
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  // box-shadow: 0px 0px 6px 0px hsla(0, 0%, 100%, .5), 0px 0px 6px 0px rgba(29, 41, 57, .1);
  box-shadow: -8px -8px 24px 0px hsla(0, 0%, 100%, .5), 0px 0px 24px 0px rgba(29, 41, 57, .15);
}

.smallCustomShadow{
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  box-shadow: 0px 0px 6px 0px hsla(0, 0%, 100%, .5), 0px 0px 6px 0px rgba(29, 41, 57, .1);
  // box-shadow: -8px -8px 24px 0px hsla(0, 0%, 100%, .5), 0px 0px 24px 0px rgba(29, 41, 57, .15);
}

.customBorderBottom {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);; 

  &:last-child {
    border-bottom: none;
  }
}


#Layer_1 path {
  stroke-width: 10;
}
/* width */ 
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
box-shadow: inset 0 0 2px rgb(164, 162, 162); 
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #2a2a66; 
border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #222253; 
}