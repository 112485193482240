@import 'variables';

.container {
    min-height: 90%;
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    h2 {
        font-weight: 700;
        font-size: 20px;
    }
}

.mainBtn {
    height: max-content;
    border: 0;
    background-color: $fourth-color;
    color: #fff;
    padding: 10px 26px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
}

.subBtn {
    height: max-content;
    border: 0;
    background-color: transparent;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #888;
    cursor: pointer;
}

.kanbanContainer {
    width: 100%;
    //overflow-x: hidden;
    //overflow-y: visible;
}

.kanbanBoard {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-grow: 1;
    flex-shrink: 0;
    //overflow-x: auto;
    //overflow-y: visible;
    min-height: 50vh;
    padding-bottom: 22px;
}

.kanbanColumn {

    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    // background-color: #FCFCFA;
    padding: 8px 0;
    height: 100%;

    .header {
        padding: 0 10px;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        padding: 10px;
    }
}
.closed_kanbanColumn{
    width: 30px;
    align-items: center;
    justify-content: center;
}
.openned_kanbanColumn{
    min-width: 260px;
    max-width: 260px;
}
.task {
    background: #fff;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    // box-shadow: -2px -2px 6px 0px hsla(0, 0%, 100%, .5), 2px 2px 6px 0px rgba(29, 41, 57, .1);
    box-shadow: 0px 0px 6px 0px hsla(0, 0%, 100%, .5), 0px 0px 6px 0px rgba(29, 41, 57, .1);
    font-size: 14px;
    cursor: pointer;
    position: relative;
    width: 100%;

    .taskEditIcon {
        height: 28px;
        width: 28px;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        border-radius: 25px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 6px;
        right: 6px;
        background-color: #fff;
        display: none;
    }

    &:hover {
        background-color: rgba($color: #000000, $alpha: 0.05);

        .taskEditIcon {
            display: flex;
        }
    }
}

.priority {
    background-color: rgba($color: #000000, $alpha: 0.1);
    padding: 2.5px 12px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    width: max-content;
    cursor: pointer;
}

.medium {
    background-color: #FEEBC8;
}

.hight {
    color: #5D1715;
    background-color: #FFE2DD;
}

.status {
    background-color: rgba($color: #000000, $alpha: 0.1);
    padding: 4px 12px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #000;
}

.progress {
    background-color: #D3E5EF;
}

.finish {
    background-color: #DBEDDB;
}

.pause{
    background-color: rgb(254 249 195);
}

.floatStatusMenu {
    position: absolute;
    left: 0;
    top: 0;
    // transform: translate(0, 0);
    z-index: 10;
    height: max-content;
    width: max-content;
    min-width: 100px;
    background-color: #fff;
    // padding: 8px 16px 8px 8px;
    border-radius: 8px;
    border: 1px solid $fourth-color;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        width: max-content;
        cursor: pointer;
        margin: 8px 16px 8px 8px;

        &:hover {
            background-color: $third-color;
            color: $second-color;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    .currStatus {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
        width: 100%;
        margin: 0;
        padding: 8px;
        font-size: 12px;
        font-weight: 600;
        background-color: rgba($color: #000000, $alpha: 0.07);
        border-radius: 8px 8px 0 0;
        cursor: default;
        color: #000;

        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.07);
            color: #000;
        }
    }

    .searchField {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
        margin: 0;
        padding: 8px;
        font-weight: 600;
        background-color: rgba($color: #000000, $alpha: 0.07);
        border-radius: 8px 8px 0 0;
        cursor: default;
        display: flex;
        align-items: center;
        gap: 6px;

        input {
            padding: 2px 12px;
            border-radius: 6px;
        }

        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.07);
            color: #000;
        }
    }

    .userSelect {
        width: 100%;
        margin: 0;
        padding: 8px 12px;

        &:last-child {
            border-radius: 0 0 8px 8px;
        }
    }
}

.floatMenuWrapper {
    position: absolute;
    min-width: 100px;
    // height: 100px;
    bottom: -12px;
    transform: translate(0, 100%);
    z-index: 10;

    height: max-content;
    width: max-content;

    // background-color: $second-color;
    background-color: #fff;
    padding: 8px 0;
    border-radius: 8px;
    border: 1px solid $fourth-color;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .floatMenuItem {
        color: rgba($color: #000, $alpha: 0.6);
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        // background-color: red;
        padding: 6px 12px;
        // border-radius: 6px;
        position: relative;

        &:hover {
            background-color: $third-color;
            color: $second-color;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}

.subFiltersMenuWrapper {
    position: absolute;
    min-width: 100px;
    left: 0;
    top: 0;
    transform: translate(-100%, 0%);
    height: max-content;
    width: max-content;
    background-color: #fff;
    padding: 8px 0;
    border-radius: 8px;
    border: 1px solid $fourth-color;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

.statusWrapper {
    li {
        padding: 6px 8px;
        cursor: pointer;

        &:hover {
            background-color: $third-color;
        }
    }

    .status {
        margin-left: 12px;
        background-color: rgba($color: #000000, $alpha: 0.1);
        padding: 2.5px 12px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        color: #000;
        width: max-content;
        cursor: pointer;
    }

    .progress {
        background-color: #D3E5EF;
    }

    .finish {
        background-color: #DBEDDB;
    }

    .medium {
        background-color: #FEEBC8;
    }

    .hight {
        color: #5D1715;
        background-color: #FFE2DD;
    }

}
