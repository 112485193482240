@import 'variables';

.container{
    background-color: #131133;
    height: 100vh;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
}
.content{
    width: 45vw;
    height: calc(100%);
    background-color: #fff;
    //background-color: #F8F9FB;
    border-radius: 16px;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 8%;
    position: relative;
}

.titleWrapper{
    margin-bottom: 42px;
}
.title{
    color: $second-color;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
}
.error{
    color: $error-color;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.oldUser{
    position: absolute;
    bottom: 22px;
    font-size: 14px;
    color: #888;

    span{
        font-weight: 600;
        color: $first-color;
        cursor: pointer;
    }
    span:hover{
        text-decoration: underline;
    }
}
